import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getProjects,
  deleteProject,
  changeStatusProject,
  changeOrderProjects,
} from "../../../../api/project";
import { Button, Tooltip, Switch, FormControlLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";

function getData(projects) {
  let data = [];
  for (let i = 0; i < projects.length; ++i) {
    const elem = {};
    elem.title = projects[i].title.length
      ? projects[i].title
      : "_BLANK_PROJECT_";
    elem.description = projects[i].description;
    elem.createdAt = projects[i].createdAt;
    elem.id = projects[i]._id;
    elem.active = projects[i].active;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  active: false,
};

export default function ProjectsPage() {
  const [projectId, setprojectId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  //Funcion para modificar el orden de los proyectos
  const handleSort = async (direction, cell) => {
    const currentIndex = filteredData.findIndex((item) => item._id === cell);
    let swapIndex = -1;

    if (direction === "up" && currentIndex > 0) {
      swapIndex = currentIndex - 1;
    } else if (direction === "down" && currentIndex < filteredData.length - 1) {
      swapIndex = currentIndex + 1;
    }

    if (swapIndex !== -1) {
      let newData = [...filteredData];
      [newData[currentIndex], newData[swapIndex]] = [
        newData[swapIndex],
        newData[currentIndex],
      ];
      changeOrderProjects(
        newData[currentIndex]._id,
        newData[swapIndex]._id
      ).then((res) => {
        if (res.status === 200) setRefresh(true);
        else
          alertError({
            error: res,
            customMessage: "No se ha podido actualizar el orden.",
          });
      });
    }
  };

  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-project/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Desactivar" : "Activar"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  console.log(elem);
                  setprojectId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOffIcon />
                ) : (
                  <ToggleOnIcon style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setprojectId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
        {data.length > 1 && user?.role === "admin" && (
          <>
            <Tooltip title="Mover arriba">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => handleSort("up", cell)}
                disabled={
                  filteredData.findIndex((item) => item._id === cell) === 0
                }
              >
                <ArrowUpward />
              </Button>
            </Tooltip>
            <Tooltip title="Mover abajo">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => handleSort("down", cell)}
                disabled={
                  filteredData.findIndex((item) => item._id === cell) ===
                  filteredData.length - 1
                }
              >
                <ArrowDownward />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "title", text: "Title", sort: true },
    // { dataField: "description", text: "Description", sort: true },

    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getProjects()
      .then((res) => {
        if (res.status === 200) {
          const sortedData = res.data.sort((a, b) => a.order - b.order);
          setFilteredData(sortedData);
          setData(sortedData);
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get projects.",
        });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.active) filter = filter && item.active;
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const renderFiltersContent = () => {
    return (
      <>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Projects list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-project")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />

          <Table data={getData(data)} columns={columns} />

          <ConfirmDialog
            title={`Are you sure you want to ${
              projectId?.active ? "disable" : "enable"
            } this project?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              console.log(projectId);
              changeStatusProject(projectId._id, !projectId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    alertSuccess({
                      title: `${projectId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `project ${
                        projectId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      projectId?.active ? "disable" : "enable"
                    } project.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this project?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteProject(projectId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "project removed successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove project.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
