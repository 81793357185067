import { authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Get all logos
export const getLogos = () => {
  return authClient().get(`${API}/logo?getDisabled=true`);
};

// Get logo by id
export const getLogoById = (id) => {
  return authClient().get(`${API}/logo/${id}`);
};

// Delete logo
export const deleteLogo = (id) => {
  return authClient().delete(`${API}/logo/${id}`);
};

// Create logo
export const postLogo = async (logo, img) => {
  if (img) {
    const res = await uploadSingleFile(img, "logos");
    logo.imgUrl = res.data.fileURL;
  }
  return authClient().post(`${API}/logo`, logo);
};

// Update logo
export const updateLogo = async (id, logo, img) => {
  if (img) {
    const res = await uploadSingleFile(img, "logos");
    logo.imgUrl = res.data.fileURL;
  }
  return authClient().put(`${API}/logo/${id}`, logo);
};

// Change status logo
export const changeStatusLogo = async (id, active) => {
  return authClient().put(`${API}/logo/change-status/${id}`, { active });
};
