import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import Editor from "../../../components/editor/Editor";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteProject,
  getProjectById,
  postProject,
  updateProject,
} from "../../../../api/project";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import { ArrowUpward, ArrowDownward, Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { buttonsStyle } from "../../../components/tables/table";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyProject() {
  return {
    title: "",
    description: "",
    mainImgUrl: "",
    gallery: [],
    background: "#ffffff",
    order: 0,
    blank: false,
    active: true,
  };
}

export default function EditProjectsPage() {
  const [project, setProject] = useState(getEmptyProject());
  const [mainImgUrl, setMainImgUrl] = useState(null);
  const [openPreviewDialogImage, setOpenPreviewDialogImage] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [gallery, setGallery] = useState([]);
  const projectId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!projectId) {
      disableLoadingData();
      return;
    }
    getProjectById(projectId)
      .then((res) => {
        if (res.status === 200) {
          const project = res.data;
          setProject(project);
          setGallery(project.gallery);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get project." });
        history.push("/projects");
      });
  }, [projectId, disableLoadingData, history]);

  function saveProject() {
    let saveProject = project;
    if (!projectId) {
      postProject(saveProject, mainImgUrl, gallery)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Project successfully created.",
            });
            history.push("/projects");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save project.",
          });
        });
    } else {
      updateProject(projectId, saveProject, mainImgUrl, gallery)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/projects");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setProject({ ...project, [element]: event.target.value });
  };

  const handleChangeEditor = (element, value) => {
    setProject({ ...project, [element]: value });
  };

  const renderGallery = () => {
    return (
      <div>
        <label htmlFor={"upload-gallery"}>
          <input
            style={{ display: "none" }}
            id={"upload-gallery"}
            name={"upload-gallery"}
            type="file"
            accept={"image/*"}
            onChange={(e) => {
              const files = e.target.files;
              if (files.length > 0) {
                setGallery([...gallery, ...files]);
              }
            }}
            multiple
          />
          <Button
            style={{ marginRight: "15px" }}
            color="secondary"
            component="span"
            variant="outlined"
            disabled={project.blank}
          >
            Upload images
          </Button>
        </label>
        {gallery.map((file, index) => {
          return (
            <div key={index}>
              <Tooltip title={"Preview Image" + (index + 1)}>
                <Button
                  size="small"
                  onClick={() => setOpenPreviewDialogImage(index)}
                  style={{ ...buttonsStyle, marginRight: "15px" }}
                >
                  <Visibility />
                </Button>
              </Tooltip>
              <PreviewDialog
                title={"Preview Image" + (index + 1)}
                open={openPreviewDialogImage === index}
                setOpen={setOpenPreviewDialogImage}
                src={
                  gallery && gallery[index]
                    ? gallery[index] instanceof File
                      ? URL.createObjectURL(gallery[index])
                      : `${SERVER_URL}/${gallery[index]}`
                    : `${SERVER_URL}/${project.gallery[index]}`
                }
              />
              <span>
                {file instanceof File ? file.name : file?.split(/-(.*)/s)[1]}
              </span>
              <Tooltip title="Delete">
                <Button
                  size="small"
                  onClick={() => {
                    const newGallery = gallery.filter((f, i) => i !== index);
                    setGallery(newGallery);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Move up">
                <span>
                  <Button
                    size="small"
                    style={buttonsStyle}
                    disabled={index === 0}
                    onClick={() => handleMoveImage(index, index - 1)}
                  >
                    <ArrowUpward />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Move down">
                <span>
                  <Button
                    size="small"
                    disabled={
                      index === gallery.length - 1 || index === gallery.length
                    }
                    style={{ ...buttonsStyle, marginRight: "1em" }}
                    onClick={() => handleMoveImage(index, index + 1)}
                  >
                    <ArrowDownward />
                  </Button>
                </span>
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  };

  const handleMoveImage = (index, newIndex) => {
    const images = [...gallery];
    const aux = images[index];
    images[index] = images[newIndex];
    images[newIndex] = aux;
    setGallery(images);
  };

  useEffect(() => {
    console.log("PROJECT", project);
  }, [project]);

  useEffect(() => {
    console.log("GALLERY", gallery);
  }, [gallery]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit project"></CardHeader>
          <CardBody>
            <TextField
              id={`title`}
              label="Title"
              value={project.title}
              onChange={handleChange("title")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              disabled={project.blank}
              required
            />
            <br />
            <TextField
              id={`background`}
              label="Background color"
              value={project?.background || "#ffffff"}
              onChange={handleChange("background")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              disabled={project.blank}
              required
            />
            <br />
            <Editor
              body={project.description || ""}
              setBody={(_newBody) =>
                handleChangeEditor("description", _newBody)
              }
              className="max-height"
              placeholder={"Enter project description here..."}
              label="Description *"
              disabled={project.blank}
            />
            <br />
            <label htmlFor={"upload-main-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-main-image"}
                name={"upload-main-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = originalFile?.name;
                  const blob = new Blob([originalFile], {
                    type: originalFile?.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile?.type,
                  });
                  setMainImgUrl(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
                disabled={project.blank}
              >
                {mainImgUrl || (project.mainImgUrl && project.mainImgUrl !== "")
                  ? "Change main image"
                  : "Upload main image"}
              </Button>
            </label>
            {(mainImgUrl ||
              (project.mainImgUrl && project.mainImgUrl !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview main Image"}
                  open={openPreviewDialogImage}
                  setOpen={setOpenPreviewDialogImage}
                  src={
                    mainImgUrl
                      ? URL.createObjectURL(mainImgUrl)
                      : `${SERVER_URL}/${project.mainImgUrl}`
                  }
                />
                <span>
                  {mainImgUrl
                    ? mainImgUrl?.name
                    : project.mainImgUrl && project.mainImgUrl !== ""
                    ? project.mainImgUrl?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview main Image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialogImage(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (mainImgUrl) setMainImgUrl(null);
                      else setProject({ ...project, mainImgUrl: "" });
                    }}
                    disabled={project.blank}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            {renderGallery()}
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={project.blanK}
                  onChange={() =>
                    setProject({ ...project, blank: !project.blank })
                  }
                  name="checkBlank"
                />
              }
              label="Blank"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={project.active}
                  onChange={() =>
                    setProject({ ...project, active: !project.active })
                  }
                  name="checkActive"
                />
              }
              label="Active"
            />
            <br />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/projects")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveProject()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save project
        </Button>
        {projectId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete project
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this project?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteProject(projectId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Project deleted successfully",
                      });
                      history.push("/projects");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete project.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
