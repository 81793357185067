import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Button, TextField } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { getContactById } from "../../../../api/contact";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError } from "../../../../utils/logger";

export default function ViewContactsPage() {
  const [contact, setContact] = useState({});
  const contactId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!contactId) {
      disableLoadingData();
      return;
    }
    getContactById(contactId)
      .then((res) => {
        if (res.status === 200) {
          setContact(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get contact." });
        history.push("/contacts");
      });
  }, [contactId, disableLoadingData, history]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="View contact"></CardHeader>
          <CardBody>
            <TextField
              id={`name`}
              label="Full name"
              value={contact.name || "---"}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              disabled
            />
            <TextField
              id={`email`}
              label="Email"
              value={contact.email || "---"}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              disabled
            />
            <TextField
              id={`subject`}
              label="Asunto"
              value={contact.subject || "---"}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              disabled
            />
            <TextField
              id={`message`}
              label="Message"
              value={contact.message || "---"}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              disabled
              multiline
              rows={12}
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/contacts")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
      </>
    );
}
