import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  Tooltip,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteLogo,
  getLogoById,
  postLogo,
  updateLogo,
} from "../../../../api/logo";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import DeleteIcon from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import { buttonsStyle } from "../../../components/tables/table";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyLogo() {
  return {
    name: "",
    imgUrl: "",
    frequency: "",
    active: true,
  };
}

export default function EditLogosPage() {
  const [logo, setLogo] = useState(getEmptyLogo());
  const [mainImgUrl, setMainImgUrl] = useState(null);
  const [openPreviewDialogImage, setOpenPreviewDialogImage] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const logoId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!logoId) {
      disableLoadingData();
      return;
    }
    getLogoById(logoId)
      .then((res) => {
        if (res.status === 200) {
          const logo = res.data;
          setLogo(logo);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get logo." });
        history.push("/logos");
      });
  }, [logoId, disableLoadingData, history]);

  function saveLogo() {
    let saveLogo = logo;
    if (!logoId) {
      postLogo(saveLogo, mainImgUrl)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Logo successfully created.",
            });
            history.push("/logos");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save logo.",
          });
        });
    } else {
      updateLogo(logoId, saveLogo, mainImgUrl)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/logos");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setLogo({ ...logo, [element]: event.target.value });
  };

  useEffect(() => {
    console.log("LOGO:", logo);
  }, [logo]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit logo"></CardHeader>
          <CardBody>
            <TextField
              id={`name`}
              label="Nombre"
              value={logo.name}
              onChange={handleChange("name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <br />
            <label htmlFor={"upload-main-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-main-image"}
                name={"upload-main-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = originalFile?.name;
                  const blob = new Blob([originalFile], {
                    type: originalFile?.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile?.type,
                  });
                  setMainImgUrl(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {mainImgUrl || (logo.imgUrl && logo.imgUrl !== "")
                  ? "Change main image"
                  : "Upload main image"}
              </Button>
            </label>
            {(mainImgUrl || (logo.imgUrl && logo.imgUrl !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview main Image"}
                  open={openPreviewDialogImage}
                  setOpen={setOpenPreviewDialogImage}
                  src={
                    mainImgUrl
                      ? URL.createObjectURL(mainImgUrl)
                      : `${SERVER_URL}/${logo.imgUrl}`
                  }
                />
                <span>
                  {mainImgUrl
                    ? mainImgUrl?.name
                    : logo.imgUrl && logo.imgUrl !== ""
                    ? logo.imgUrl?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview main Image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialogImage(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (mainImgUrl) setMainImgUrl(null);
                      else setLogo({ ...logo, mainImgUrl: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            <br />
            <InputLabel id="fequency">Frecuencia de aparición</InputLabel>
            <Select
              labelId="fequency"
              id="fequency"
              value={logo.frequency}
              onChange={handleChange("frequency")}
              style={{ width: "20%" }}
            >
              <option value={"quite-frequent"}>Muy frecuente</option>
              <option value={"normal"}>Normal</option>
              <option value={"rarely"}>Poco frecuente</option>
            </Select>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={logo.active}
                  onChange={() => setLogo({ ...logo, active: !logo.active })}
                  name="checkActive"
                />
              }
              label="Active"
            />
            <br />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/logos")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveLogo()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save logo
        </Button>
        {logoId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete logo
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this logo?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteLogo(logoId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Logo deleted successfully",
                      });
                      history.push("/logos");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete logo.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
