import { authClient, API } from "../index";
import { uploadSingleFile, uploadFilesGetLinks } from "../files";

// Get all projects
export const getProjects = () => {
  return authClient().get(`${API}/project?getDisabled=true`);
};

// Get project by id
export const getProjectById = (id) => {
  return authClient().get(`${API}/project/${id}`);
};

// Delete project
export const deleteProject = (id) => {
  return authClient().delete(`${API}/project/${id}`);
};

// Create project
export const postProject = async (project, mainImage, gallery) => {
  if (mainImage) {
    const res = await uploadSingleFile(mainImage, "projects");
    project.mainImgUrl = res.data.fileURL;
  }
  if (gallery) {
    const imagesURLs = await uploadFilesGetLinks(gallery, "projects");
    project.gallery = imagesURLs;
  }
  return authClient().post(`${API}/project`, project);
};

// Update project
export const updateProject = async (id, project, mainImage, gallery) => {
  if (mainImage) {
    const res = await uploadSingleFile(mainImage, "projects");
    project.mainImgUrl = res.data.fileURL;
  }
  if (gallery) {
    project.gallery = gallery;
    for (const index in gallery) {
      if (gallery[index] instanceof File) {
        const response = await uploadSingleFile(gallery[index], "projects");
        project.gallery[index] = response.data.fileURL;
      }
    }
  }
  return authClient().put(`${API}/project/${id}`, project);
};

// Change status project
export const changeStatusProject = async (id, active) => {
  return authClient().put(`${API}/project/change-status/${id}`, { active });
};

// Switch projects order
export const changeOrderProjects = async (id1, id2) => {
  return authClient().put(`${API}/project/change-order/${id1}/${id2}`);
};

export const countProjects = async () => {
  return authClient().get(`${API}/project/count`);
};
